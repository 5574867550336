import axios from 'axios';
import Vue from 'vue';
import to from 'await-to-js';
import {
  find,
  isArray,
  uniqueId,
  get,
  forEach,
  has,
  isEmpty,
  isNumber,
  includes,
  replace,
  merge,
  map,
  uniqBy,
  keys,
  cloneDeep,
  values,
  isObject,
  range,
} from 'lodash';
import downloadXlsxFile from '../utils/download-xlsx-file';
import { formatFilters } from '../utils/filters';
import computeScenarioPrice from '../utils/compute-scenario-price';
import sortStoreGroups from '../../utils/sort-store-groups-util';
import { mapAggregations } from '@sharedModules/data/utils/time-flexible-utils';
import timeFlexibleEndings from '@enums/time-flexible-endings';
import { yearly } from '@enums/historical-periods';
import { jobStatuses } from '@enums/jobapi';
import {
  rootHierarchyLevel,
  unitLevel,
  categoryLevel,
  architectureGroupLevel,
  pricingGroupLevel,
} from '@enums/hierarchy';
import {
  competitorIndexWeightingTypes,
  displayRulesListView,
  displayHistoricalDropdown,
  getGroupedAggregations,
  getSingleAggregations,
  saveResultsToDatabricks,
} from '@enums/feature-flags';
import { categoryManagerPermissions, blacklistedAlerts } from '@enums/alert-permissions';
import filterPredicates from '@enums/filter-predicates';
import { paramsSerializer } from '../../utils/legacy-params-serializer';

const allHierarchyLevels = [
  rootHierarchyLevel,
  unitLevel,
  categoryLevel,
  pricingGroupLevel,
  architectureGroupLevel,
];

// Used for tasks where we need to wait for argo to complete its job
const argoStatusMap = {
  finished: jobStatuses.argoFinished,
  failed: jobStatuses.argoFailed,
};

const makeHierarchyKey = (aggregationLevel, parentId, competitorNames) =>
  `${aggregationLevel}::${parentId}::${competitorNames.join('::')}`;

const makeGroupedHierarchyKey = competitorNames => `${competitorNames.join('::')}`;

const defaultExpandedHierarchyLevelItems = rootHierarchyLevelItemId => [
  rootHierarchyLevelItemId,
  rootHierarchyLevelItemId,
  null,
  null,
  null,
];

const getWorkpackageDescription = ({ context, workpackageId } = {}) => {
  const { rootState } = context;
  const workpackage = find(rootState.workpackages.workpackages, { _id: workpackageId });
  const { description } = workpackage || rootState.workpackages.selectedWorkpackage;

  return description;
};

const triggerStatusNotification = ({
  context,
  jobId,
  jobStatus,
  notificationPayload,
  argoWorkflow = false,
} = {}) => {
  const { commit } = context;

  const notificationJobStatus = argoWorkflow ? argoStatusMap[jobStatus] || jobStatus : jobStatus;

  console.log('statusToSend', {
    id: `${jobId}:${notificationJobStatus}:${new Date().toISOString()}`,
    jobStatus: notificationJobStatus,
    notificationPayload,
    baseTranslation: `notifications.${jobId}`,
  });
  commit(
    'notifications/addNotification',
    {
      id: `${jobId}:${notificationJobStatus}:${new Date().toISOString()}`,
      jobStatus: notificationJobStatus,
      notificationPayload,
      baseTranslation: `notifications.${jobId}`,
    },
    { root: true }
  );
};

const approxEq = (v1, v2, epsilon) => {
  if (epsilon == null) {
    epsilon = 0.001;
  }
  return Math.abs(v1 - v2) < epsilon;
};
const objectDifferenceLogger = (objectA, objectB, keysToIgnore = []) => {
  const objectDifferences = Object.keys(objectA).reduce((diff, key) => {
    if (
      isObject(objectA[key]) ||
      objectA[key] === objectB[key] ||
      approxEq(objectA[key], objectB[key]) ||
      keysToIgnore.includes(key)
    )
      return diff;
    if (!has(objectB, key)) {
      diff += `\n${key} Old: ${objectA[key]} Grouped: 'missing'`;
      return diff;
    }
    diff += `\n${key} Old: ${objectA[key]} Grouped: ${objectB[key]} Delta: ${Math.abs(
      objectA[key] - objectB[key]
    )}`;
    return diff;
  }, '');
  if (!isEmpty(objectDifferences)) console.error(objectDifferences);
  else console.log('No difference');
};

const getFilteredAlerts = (alerts, isPricingSpecialist) => {
  if (isEmpty(alerts)) {
    return [];
  }
  const nonBlacklistedAlerts = alerts.filter(alert => !blacklistedAlerts.has(alert.alertType));
  if (isPricingSpecialist) return nonBlacklistedAlerts;
  return nonBlacklistedAlerts.filter(alert => categoryManagerPermissions.includes(alert.alertType));
};

let activeSource = null;
const fetchItemsWithLimit = async (
  { rootState, rootGetters, commit, getters },
  { isUnitManagerView, levelEntryKey, formattedAttributeFilters },
  limit = 0
) => {
  commit('setItems', { items: [] });

  const scenarioKey = rootState.filters.scenario;
  const workpackageId = rootState.workpackages.selectedWorkpackage._id;
  const competitorNames = getters.selectedCompetitorNames;

  const baseUrl = `/api/workpackage-product/workpackage/${workpackageId}/gridview/${levelEntryKey}`;
  const url = isUnitManagerView ? `${baseUrl}/unit` : `${baseUrl}/scenario/${scenarioKey}`;
  const params = {
    attributeFilters: formattedAttributeFilters,
    limit,
    competitorNames,
  };

  // stream response if in unit manager view as the number of products is so large
  if (isUnitManagerView) {
    if (activeSource) {
      console.log('new GridView event source required - closing existing one');
      activeSource.close();
    }

    // legacy params serializer from axios 0.2x.
    const fullUrl = `${url}?${paramsSerializer.serialize(params)}`;

    const source = new EventSource(fullUrl, { withCredentials: true });

    let isFirstChunk = true;
    source.addEventListener(
      'message',
      function(e) {
        const items = JSON.parse(e.data);
        // this can be useful as the data size is too large to be inspected in chrome dev tools
        console.log(items.length, ' items received for Grid View');

        const isPricingSpecialist = rootGetters['context/isPricingSpecialist'];
        items.forEach(item => {
          item.filteredAlerts = getFilteredAlerts(item.alerts, isPricingSpecialist);
          // filtering by alert type performs by alertCounts field, hence all alerts are not required anymore
          delete item.alerts;
        });

        if (isFirstChunk) {
          commit('setItems', { items });
          isFirstChunk = false;
        } else {
          commit('appendItems', { items });
        }
      },
      false
    );
    source.addEventListener(
      'error',
      function(e) {
        console.log('Closing Grid View EventSource');
        if (e.eventPhase === EventSource.CLOSED) {
          source.close();
          activeSource = null;
        }
      },
      false
    );
    activeSource = source;
  } else {
    const [err, response] = await to(axios.get(url, { params }));

    if (err) {
      throw new Error(err.message);
    }

    const { data: items } = response;

    const isPricingSpecialist = rootGetters['context/isPricingSpecialist'];
    items.forEach(item => {
      item.filteredAlerts = getFilteredAlerts(item.alerts, isPricingSpecialist);
      // filtering by alert type performs by alertCounts field, hence all alerts are not required anymore
      delete item.alerts;
    });

    commit('setItems', { items });
  }
};

const mapStoreGroupAggregationsData = ({ data, products, storeGroupOrder }) => {
  if (isEmpty(data)) {
    // Create default aggregations -> all we need is the _id field for display
    // all other fields will default to - or 0 due to the number formatter
    const toolStoreGroupsInPG = uniqBy(
      map(products, item => ({ _id: item.toolStoreGroupDescription })),
      '_id'
    );
    return sortStoreGroups(toolStoreGroupsInPG, storeGroupOrder, '_id');
  }
  return sortStoreGroups(data, storeGroupOrder, '_id');
};

const areStoreGroupAggregationsEqual = ({
  level,
  parentId,
  scenarioKey,
}) => storeGroupAggregations =>
  storeGroupAggregations.parentId === parentId &&
  storeGroupAggregations.level === level &&
  storeGroupAggregations.scenarioKey === scenarioKey;

const getFetchStoreGroupAggregationsParams = ({ level, parentId, scenarioKey }) => {
  const dynamicParams = scenarioKey
    ? {
        pricingGroupId: parentId,
        scenarioKey,
      }
    : {
        aggregationLevel: level + 1,
        parentId,
      };
  return {
    aggregateStoreGroups: true,
    ...dynamicParams,
  };
};

const volumeKpiInputFields = item => {
  return keys(timeFlexibleEndings).reduce((acc, ending) => {
    acc[`volume_${timeFlexibleEndings[ending]}`] = get(item, `volume${ending}`, 0);
    return acc;
  }, {});
};

const getExtraFetchHierarchyItemAggregationParams = (
  state,
  {
    aggregationLevel,
    parentId,
    aggregateScenarios,
    aggregateStoreGroups,
    pricingGroupId,
    scenarioKey,
    historicalPeriod,
  } = {}
) => {
  if (aggregateScenarios) {
    return {
      aggregateScenarios,
      pricingGroupId,
      activeTableFilters: state.activeTableFilters,
    };
  }
  if (aggregateStoreGroups && scenarioKey) {
    return {
      aggregateStoreGroups,
      scenarioKey,
      pricingGroupId,
      activeTableFilters: state.activeTableFilters,
    };
  }
  const basicParams = {
    parentId: parentId || state.rootHierarchyLevelItemId,
    parentLevel: Math.max(0, aggregationLevel - 1),
    aggregationLevel,
    historicalPeriod,
  };
  if (aggregateStoreGroups) {
    basicParams.aggregateStoreGroups = true;
  }
  return basicParams;
};

const getHierarchyLevelAggregationRequestKey = ({ level, aggregateStoreGroups }) =>
  aggregateStoreGroups ? `${level}-store-group` : level;

const defaultHierarchyLevelAggregationLoadings = () => {
  const loadings = {};
  allHierarchyLevels.forEach(level => {
    // we always load aggregations for the root level
    loadings[getHierarchyLevelAggregationRequestKey({ level, aggregateStoreGroups: false })] =
      level === rootHierarchyLevel;
    loadings[getHierarchyLevelAggregationRequestKey({ level, aggregateStoreGroups: true })] = false;
  });
  return loadings;
};

const unitAggregationsLoadingKey = getHierarchyLevelAggregationRequestKey({
  level: unitLevel,
});

const getInitialState = () => {
  return {
    rootHierarchyLevelItemId: '',
    hierarchy: {},
    groupedHierarchy: {},
    children: {},
    items: [],
    expandedHierarchyLevelItems: [],
    calculatingScenarioResults: false,
    calculatingPgResults: {},
    calculatingSpecificPgResults: {},
    recalculatingAll: false,
    loadingRecalculateAllResults: false,
    selectedCompetitors: [],
    loading: false,
    loadingMsg: null,
    aggregationLoadings: defaultHierarchyLevelAggregationLoadings(),
    timeFlexibleAggregationsLoadings: defaultHierarchyLevelAggregationLoadings(),
    timeFlexibleAggregationRequestsCanceller: {},
    pricingGroupsToRelease: [],
    categoryToRelease: null,
    activeTableFilters: [],
    previousActiveTableFilters: [],
    selectedWeighting: competitorIndexWeightingTypes.sales, // set from feature flags fetched from client config store
    showRegularImpact: false,
    selectedHistoricalPeriod: yearly,
    failedRuns: [],
    expandedProduct: null,
    alertFilter: null,
    alertCounts: {},
    loadingProductSettings: false,
    loadingStoreGroupAggregations: false,
    expandedStoreGroupAggregations: [],
    expandedProductSettings: {},
    isFilteringByArchitectureGroupEnabled: false,
    downloadingItems: false,
    isCachedResult: false,
    cachedResultTimestamp: null,
  };
};

const store = {
  namespaced: true,

  state: getInitialState(),

  getters: {
    items: state => state.items,

    hierarchyLevelItems: (state, getters, rootState) => ({ level, parentId } = {}) => {
      const getSingleAggregationsFlag = rootState.clientConfig.toggleLogic[getSingleAggregations];
      if (getSingleAggregationsFlag) {
        const hierarchyKey = makeHierarchyKey(level, parentId, getters.selectedCompetitorNames);
        return state.hierarchy[hierarchyKey] || [];
      }
      const hierarchyKey = makeGroupedHierarchyKey(getters.selectedCompetitorNames);
      return (
        values(state.hierarchy[hierarchyKey][level]).filter(agg => agg.parentId === parentId) || []
      );
    },

    rootHierarchyItem: (state, getters, rootState) => {
      const getSingleAggregationsFlag = rootState.clientConfig.toggleLogic[getSingleAggregations];
      if (getSingleAggregationsFlag) {
        const hierarchyKey = makeHierarchyKey(
          0,
          state.rootHierarchyLevelItemId,
          getters.selectedCompetitorNames
        );
        return (state.hierarchy[hierarchyKey] || [])[0];
      }
      const hierarchyKey = makeGroupedHierarchyKey(getters.selectedCompetitorNames);
      return (values(state.hierarchy[hierarchyKey][0]) || [])[0];
    },

    rootHierarchyItemChildren: (state, getters, rootState) => {
      const getSingleAggregationsFlag = rootState.clientConfig.toggleLogic[getSingleAggregations];
      if (getSingleAggregationsFlag) {
        const hierarchyKey = makeHierarchyKey(
          1,
          state.rootHierarchyLevelItemId,
          getters.selectedCompetitorNames
        );
        return state.hierarchy[hierarchyKey] || [];
      }
      const hierarchyKey = makeGroupedHierarchyKey(getters.selectedCompetitorNames);
      return values(state.hierarchy[hierarchyKey][1]) || [];
    },

    // for generating a matching hierarchy key in hierarchy.vue
    hierarchyKey: (state, getters, rootState) => ({ level, parentId } = {}) => {
      const getSingleAggregationsFlag = rootState.clientConfig.toggleLogic[getSingleAggregations];
      if (getSingleAggregationsFlag) {
        return makeHierarchyKey(level, parentId, getters.selectedCompetitorNames);
      }
      return makeGroupedHierarchyKey(getters.selectedCompetitorNames);
    },

    groupedHierarchyKey: (state, getters) => () => {
      return makeGroupedHierarchyKey(getters.selectedCompetitorNames);
    },

    selectedCompetitorNames: state =>
      state.selectedCompetitors.map(competitor => competitor.competitorDescription),

    selectedCompetitorDisplayDescriptions: state =>
      state.selectedCompetitors.map(competitor => competitor.competitorDisplayDescription),

    expandedStoreGroupAggregation: (state, getters) => ({ level, parentId, scenarioKey } = {}) =>
      getters.storeGroupAggregactionsBySelectedHistoricalPeriod.find(
        areStoreGroupAggregationsEqual({ level, parentId, scenarioKey })
      ),

    storeGroupAggregactionsBySelectedHistoricalPeriod: ({
      expandedStoreGroupAggregations,
      selectedHistoricalPeriod,
    }) => {
      const mapAggregationsBySelectedHistoricalPeriod = mapAggregations(selectedHistoricalPeriod);
      return expandedStoreGroupAggregations.map(aggregation => {
        return {
          ...aggregation,
          data: aggregation.data.map(item => ({
            _id: item._id,
            toolStoreGroupKey: item.toolStoreGroupKey,
            ...mapAggregationsBySelectedHistoricalPeriod(item),
          })),
        };
      });
    },

    timeFlexibleAggregationsLoading: state =>
      Object.values(state.timeFlexibleAggregationsLoadings).some(Boolean),

    aggregationsLoading: state =>
      // aggregations are slow, checking the top two levels should be sufficient
      !!state.aggregationLoadings[unitAggregationsLoadingKey],

    hasUnfinishedSpecificPgJobs(state) {
      return Object.values(state.calculatingSpecificPgResults).some(isCalculating => isCalculating);
    },
  },

  mutations: {
    setLoading(state, isLoading) {
      state.loadingMsg = null;
      state.loading = isLoading;
    },

    setLoadingWithMsg(state, loadingMsg) {
      state.loadingMsg = loadingMsg;
      state.loading = true;
    },

    setDownloadingItems(state, downloading) {
      state.downloadingItems = downloading;
    },

    setTimeFlexibleAggregationsLoading(state, { level, aggregateStoreGroups, isLoading }) {
      const key = getHierarchyLevelAggregationRequestKey({ level, aggregateStoreGroups });
      state.timeFlexibleAggregationsLoadings = {
        ...state.timeFlexibleAggregationsLoadings,
        [key]: isLoading,
      };
    },

    resetTimeFlexibleAggregationsLoading(state) {
      state.timeFlexibleAggregationsLoadings = defaultHierarchyLevelAggregationLoadings();
    },

    setAggregationLoadings(state, { level, aggregateStoreGroups, isLoading }) {
      const key = getHierarchyLevelAggregationRequestKey({ level, aggregateStoreGroups });
      state.aggregationLoadings = {
        ...state.aggregationLoadings,
        [key]: isLoading,
      };
    },

    resetAggregationLoadings(state) {
      state.aggregationLoadings = defaultHierarchyLevelAggregationLoadings();
    },

    setTimeFlexibleAggregationRequestsCanceller(state, { level, aggregateStoreGroups, canceller }) {
      const key = getHierarchyLevelAggregationRequestKey({ level, aggregateStoreGroups });
      state.timeFlexibleAggregationRequestsCanceller[key] = canceller;
    },

    removeTimeFlexibleAggregationRequestsCanceller(state, { level, aggregateStoreGroups }) {
      const key = getHierarchyLevelAggregationRequestKey({ level, aggregateStoreGroups });
      delete state.timeFlexibleAggregationRequestsCanceller[key];
    },

    resetTimeFlexibleAggregationRequestsCanceller(state) {
      state.timeFlexibleAggregationRequestsCanceller = {};
    },

    setRootHierarchyLevelItemId(state, { rootHierarchyLevelItem }) {
      state.rootHierarchyLevelItemId = rootHierarchyLevelItem.levelEntryKey;
    },

    setItems(state, { items }) {
      state.items = items;
    },

    appendItems(state, { items }) {
      state.items.push(...items);
    },

    setCategoryToRelease(state, { category }) {
      state.categoryToRelease = category;
    },

    setPricingGroupsToRelease(state, { pricingGroups }) {
      state.pricingGroupsToRelease = pricingGroups;
    },

    updatePricingGroupsToRelease(state, { pg }) {
      state.pricingGroupsToRelease.push(pg);
    },

    resetPricingGroupsToRelease(state) {
      state.pricingGroupsToRelease = [];
    },

    setHierarchyLevelItems(state, { items, aggregationLevel, parentId, selectedCompetitors }) {
      const hierarchyKey = makeHierarchyKey(aggregationLevel, parentId, selectedCompetitors);
      state.hierarchy = {
        ...state.hierarchy,
        [hierarchyKey]: items,
      };
    },

    setGroupedHierarchyLevelItems(state, { items, selectedCompetitors }) {
      const groupedHierarchyKey = makeGroupedHierarchyKey(selectedCompetitors);
      state.groupedHierarchy = {
        ...state.groupedHierarchy,
        [groupedHierarchyKey]: items,
      };
    },

    initExpandedHierarchyLevelItems(state) {
      // set state.expandedHierarchyLevelItems to default if hierarchy wasn't expanded
      state.expandedHierarchyLevelItems = state.expandedHierarchyLevelItems.length
        ? state.expandedHierarchyLevelItems
        : defaultExpandedHierarchyLevelItems(state.rootHierarchyLevelItemId);
    },

    setExpandedHierarchyLevelItems(state, expandedHierarchyLevelItems) {
      state.expandedHierarchyLevelItems = expandedHierarchyLevelItems;
    },

    setFailedRuns(state, failedRuns) {
      state.failedRuns = failedRuns;
    },

    resetExpandedHierarchyLevels(state) {
      state.expandedHierarchyLevelItems = [];
    },

    saveAndResetGridViewFilters(state) {
      state.previousActiveTableFilters = cloneDeep(state.activeTableFilters);
      state.activeTableFilters = [];
      state.isFilteringByArchitectureGroupEnabled = true;
    },

    restoreGridViewFilters(state) {
      state.activeTableFilters = state.previousActiveTableFilters;
      state.previousActiveTableFilters = [];
      state.isFilteringByArchitectureGroupEnabled = false;
    },

    setCalculatingScenarioResults(state, isCalculating) {
      state.calculatingScenarioResults = isCalculating;
    },

    setCalculatingPgResults(state, { workpackageId, levelEntryKey, isCalculating }) {
      const index = `${workpackageId}?${levelEntryKey}`;
      Vue.set(state.calculatingPgResults, index, isCalculating);
    },
    setCalculatingSpecificPgResults(state, { workpackageId, pgLevelEntryKeys, isCalculating }) {
      // if no pgLevelEntryKeys set all existing keys to false
      if (isEmpty(pgLevelEntryKeys)) {
        // loop through all existing keys and set to false
        const existingKeys = keys(state.calculatingSpecificPgResults);
        existingKeys.forEach(existingKey => {
          Vue.set(state.calculatingSpecificPgResults, existingKey, false);
        });
        return;
      }

      pgLevelEntryKeys.forEach(pgLevelEntryKey => {
        const index = `${workpackageId}?${pgLevelEntryKey}`;
        Vue.set(state.calculatingSpecificPgResults, index, isCalculating);
      });
    },

    setSelectedCompetitors(state, selectedCompetitors) {
      // setting initial default competitor selection: aggregation triggered separately
      state.selectedCompetitors = selectedCompetitors;
    },

    setSelectedCompetitorAtIndex(state, { selectedCompetitor, index }) {
      Vue.set(state.selectedCompetitors, index, selectedCompetitor);
    },

    setSelectedWeighting(state, { selectedWeighting }) {
      state.selectedWeighting = selectedWeighting;
    },

    setShowRegularImpact(state, { showRegularImpact }) {
      state.showRegularImpact = showRegularImpact;
    },

    setSelectedHistoricalPeriod(state, { selectedHistoricalPeriod }) {
      state.selectedHistoricalPeriod = selectedHistoricalPeriod;
    },

    resetState(state) {
      Object.assign(state, getInitialState());
    },

    upsertActiveTableFilter(state, filter) {
      filter.id = filter.id || uniqueId('filter');
      const existingFilter = find(state.activeTableFilters, { id: filter.id });
      // If editing, just override its props
      if (existingFilter) {
        return Object.assign(existingFilter, filter);
      }
      // Otherwise is a new filter to be added
      state.activeTableFilters = [...state.activeTableFilters, filter];
    },

    removeActiveTableFilter(state, filter) {
      state.activeTableFilters = state.activeTableFilters.filter(f => f.id !== filter.id);
    },

    setLoadingRecalculateAllResults(state, loading) {
      // Set to true at the start of run-all, set to false after fetching fresh ASR results
      state.loadingRecalculateAllResults = loading;
    },

    setRecalculatingAll(state, loading) {
      // Set to true at the start of run-all, set to false after engine finishes
      state.recalculatingAll = loading;

      if (loading) {
        state.loadingRecalculateAllResults = loading;
      }
    },

    setExpandedProduct(state, product) {
      state.expandedProduct = product;
    },

    setAlertFilter(state, alertType) {
      state.alertFilter = alertType;
    },

    setAlertCounts(state, alertCounts) {
      state.alertCounts = alertCounts;
    },

    setLoadingProductSettings(state, loading) {
      state.loadingProductSettings = loading;
    },

    setLoadingStoreGroupAggregations(state, loadingStoreGroupAggregations) {
      state.loadingStoreGroupAggregations = loadingStoreGroupAggregations;
    },

    addExpandedStoreGroupAggregation(state, { level, parentId, scenarioKey = null }) {
      const storeGroupAggregation = {
        level,
        parentId,
        scenarioKey,
        loading: true,
        data: [],
      };
      if (isEmpty(state.expandedStoreGroupAggregations)) {
        state.expandedStoreGroupAggregations = [storeGroupAggregation];
        return;
      }
      const firstSelected = state.expandedStoreGroupAggregations[0];
      if (firstSelected.parentId !== parentId || !scenarioKey) {
        state.expandedStoreGroupAggregations = [storeGroupAggregation];
        return;
      }
      state.expandedStoreGroupAggregations.push(storeGroupAggregation);
    },

    removeExpandedStoreGroupAggregation(state, { level, parentId, scenarioKey }) {
      const filterFunc = areStoreGroupAggregationsEqual({ level, parentId, scenarioKey });
      const aggIndex = state.expandedStoreGroupAggregations.findIndex(filterFunc);
      if (aggIndex === -1) return;
      state.expandedStoreGroupAggregations.splice(aggIndex, 1);
    },

    setExpandedStoreGroupAggregations(state, expandedStoreGroupAggregations) {
      state.expandedStoreGroupAggregations = expandedStoreGroupAggregations;
    },

    updateExpandedStoreGroupAggregation(
      state,
      { level, parentId, scenarioKey, loading = false, data = [], storeGroupOrder }
    ) {
      const filterFunc = areStoreGroupAggregationsEqual({ level, parentId, scenarioKey });
      const aggIndex = state.expandedStoreGroupAggregations.findIndex(filterFunc);
      if (aggIndex === -1) return;
      state.expandedStoreGroupAggregations.splice(aggIndex, 1, {
        level,
        parentId,
        scenarioKey,
        loading,
        data: mapStoreGroupAggregationsData({ data, products: state.items, storeGroupOrder }),
      });
    },

    setExpandedProductSettings(state, settings) {
      state.expandedProductSettings = settings;
    },

    clearCachedResult(state) {
      state.isCachedResult = false;
      state.cachedResultTimestamp = null;
    },

    setCachedResultTimestamp(state, timestamp) {
      state.isCachedResult = true;
      state.cachedResultTimestamp = timestamp;
    },
  },

  actions: {
    async fetchRootHierarchyLevelItemId({ rootState, commit }) {
      commit('setLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, res] = await to(
        axios.get(`/api/hierarchy/workpackage/${workpackageId}/root-hierarchy-item`)
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
      const { data: rootHierarchyLevelItem } = res;
      commit('setRootHierarchyLevelItemId', { rootHierarchyLevelItem });
      commit('setLoading', false);
    },

    async checkRecalculatingAll({ rootState, commit }) {
      const [err, res] = await to(
        axios.get(
          `/api/pricing-engine/recalculating-all/workpackage/${
            rootState.workpackages.selectedWorkpackage._id
          }`
        )
      );
      if (err) {
        commit('setRecalculatingAll', false);
        throw new Error(err.message);
      }
      const { data: recalculatingAll } = res;
      commit('setRecalculatingAll', recalculatingAll);
    },

    async checkRecalculatingPgs({ commit }, { workpackageId, levelEntryKey }) {
      const [err, res] = await to(
        axios.get(
          `/api/pricing-engine/recalculating-pgs/workpackage/${workpackageId}/${levelEntryKey}`
        )
      );
      if (err) {
        commit('setCalculatingPgResults', {
          workpackageId,
          levelEntryKey,
          isCalculating: false,
        });
        throw new Error(err.message);
      }
      const { data: isCalculating } = res;
      commit('setCalculatingPgResults', {
        workpackageId,
        levelEntryKey,
        isCalculating,
      });
    },
    async getRecalculatingSpecificPricingGroups({ commit, rootState }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      try {
        const [err, res] = await to(
          axios.get(`/api/pricing-engine/specific-pricing-groups/workpackage/${workpackageId}`)
        );
        if (err) {
          throw new Error(err.message);
        }

        const { data: recalculatingSpecificPgs } = res;

        if (recalculatingSpecificPgs.length === 0) {
          commit('setCalculatingSpecificPgResults', {
            workpackageId,
            isCalculating: false,
          });
        } else {
          const pgLevelEntryKeys = recalculatingSpecificPgs.flatMap(
            entry => entry.params.level_entry_keys
          );
          commit('setCalculatingSpecificPgResults', {
            workpackageId,
            pgLevelEntryKeys,
            isCalculating: true,
          });
        }
      } catch (error) {
        console.error('Error in getRecalculatingSpecificPricingGroups:', error);
        commit('setCalculatingSpecificPgResults', {
          workpackageId,
          isCalculating: false,
        });
      }
    },

    async fetchItems(
      { rootState, rootGetters, commit, state, getters },
      { levelEntryKey, isUnitManagerView = false }
    ) {
      if (!levelEntryKey) return;
      commit('setLoading', true);

      const { retailAttributesFilter } = rootState.filters;
      if (rootState.filters.scenario !== null || isUnitManagerView) {
        const formattedAttributeFilters = formatFilters({
          where: retailAttributesFilter,
          rootState,
        });
        // removed the limit call as is fast enough without now
        await fetchItemsWithLimit(
          { rootState, rootGetters, commit, state, getters },
          { levelEntryKey, formattedAttributeFilters, isUnitManagerView }
        );
      }
      commit('setLoading', false);
    },

    async fetchFullProductInfo(
      { rootState, getters, commit },
      { productKey, scenarioKey, toolStoreGroupKey }
    ) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const competitorNames = getters.selectedCompetitorNames;
      const [err, { data: product }] = await to(
        axios.get(`/api/workpackage-product/workpackage/${workpackageId}/${productKey}`, {
          params: { scenarioKey, toolStoreGroupKey, competitorNames },
        })
      );
      if (err) console.error('Error fetching full product info: ', err);
      commit('setExpandedProduct', product);
      return product;
    },

    async fetchProductSettings(
      { rootState, commit },
      { productKey, scenarioKey, toolStoreGroupKey }
    ) {
      commit('setLoadingProductSettings', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, { data: productSettings }] = await to(
        axios.get(
          `/api/product-settings/workpackage/${workpackageId}/scenario/${scenarioKey}/product/${productKey}`,
          { params: { toolStoreGroupKey } }
        )
      );
      if (err) {
        commit('setLoadingProductSettings', false);
        throw new Error(err.message);
      }
      commit('setLoadingProductSettings', false);
      commit('setExpandedProductSettings', productSettings[0]);
      return productSettings[0];
    },

    async fetchAggregatedHierarchyLevelItemsTimeFlexible(
      { commit, state, getters, rootState },
      fetchAggregatedHierarchyLevelItemsParams
    ) {
      const competitorNames = getters.selectedCompetitorNames;
      const params = {
        ...fetchAggregatedHierarchyLevelItemsParams,
      };
      const basicMutationParams = {
        level: params.aggregationLevel,
        aggregateStoreGroups: params.aggregateStoreGroups,
      };
      const previousRequestCancellerKey = getHierarchyLevelAggregationRequestKey(
        basicMutationParams
      );
      if (has(state.timeFlexibleAggregationRequestsCanceller, previousRequestCancellerKey)) {
        state.timeFlexibleAggregationRequestsCanceller[previousRequestCancellerKey].cancel();
      }
      const axiosSource = axios.CancelToken.source();
      commit('setTimeFlexibleAggregationsLoading', {
        ...basicMutationParams,
        isLoading: true,
      });
      commit('setTimeFlexibleAggregationRequestsCanceller', {
        ...basicMutationParams,
        canceller: axiosSource,
      });
      delete params.historicalPeriod;
      const [err, response] = await to(
        axios.get('/api/workpackage-product/aggregated', { params, cancelToken: axiosSource.token })
      );

      if (err) {
        commit('removeTimeFlexibleAggregationRequestsCanceller', {
          ...basicMutationParams,
        });
        if (axios.isCancel(err)) {
          return;
        }
        throw new Error(err.message);
      }

      const { data: items } = response;
      commit('setTimeFlexibleAggregationsLoading', {
        ...basicMutationParams,
        isLoading: false,
      });
      commit('removeTimeFlexibleAggregationRequestsCanceller', {
        ...basicMutationParams,
      });
      if (params.aggregateStoreGroups) {
        const storeGroupOrder = rootState.clientConfig.storeGroupOrderConfig;
        commit('updateExpandedStoreGroupAggregation', {
          level: params.parentLevel,
          parentId: params.parentId,
          scenarioKey: null,
          loading: false,
          data: items,
          storeGroupOrder,
        });
        return;
      }
      commit('setHierarchyLevelItems', {
        items,
        aggregationLevel: params.aggregationLevel,
        parentId: params.parentId,
        selectedCompetitors: competitorNames,
      });
    },

    compareAggregations({ state, getters }, { level, parentId }) {
      const hierarchyKey = makeHierarchyKey(level, parentId, getters.selectedCompetitorNames);
      const fetchedHierarchy = state.hierarchy[hierarchyKey];
      fetchedHierarchy.forEach(aggregation => {
        const levelEntryKey = aggregation.levelEntryKey;
        const name = aggregation.name;
        const groupedHierarchyKey = makeGroupedHierarchyKey(getters.selectedCompetitorNames);
        const groupedAggregation =
          state.groupedHierarchy[groupedHierarchyKey][level][levelEntryKey];
        console.log(
          `Comparing aggregations for level: ${level} and levelEntryKey: ${levelEntryKey} ${name}`
        );
        objectDifferenceLogger(aggregation, groupedAggregation, ['parentId']);
      });
    },

    async fetchAggregatedHierarchyLevelItems(
      { commit, state, getters, rootState, dispatch },
      {
        aggregationLevel = 0,
        parentId,
        aggregateScenarios = false,
        aggregateStoreGroups = false,
        pricingGroupId,
        scenarioKey,
      } = {}
    ) {
      if (aggregateScenarios && !pricingGroupId) return;
      const getGroupedAggregationsFlag = rootState.clientConfig.toggleLogic[getGroupedAggregations];
      const getSingleAggregationsFlag = rootState.clientConfig.toggleLogic[getSingleAggregations];
      commit('setLoading', true);
      if (!aggregateScenarios && !aggregateStoreGroups) {
        commit('setAggregationLoadings', {
          level: aggregationLevel,
          aggregateStoreGroups,
          isLoading: true,
        });
      }
      let formattedAttributeFilters = {};
      const allTimeFlexiblePeriodsLoadingRequired =
        rootState.clientConfig.toggleLogic[displayHistoricalDropdown];

      const { retailAttributesFilter } = rootState.filters;
      if (retailAttributesFilter.length) {
        formattedAttributeFilters = formatFilters({
          where: retailAttributesFilter,
          rootState,
        });
      }

      if (isEmpty(state.selectedCompetitors)) {
        await dispatch('competitorMetadata/fetchCompetitorMetadata', null, { root: true });
      }
      const extraParams = getExtraFetchHierarchyItemAggregationParams(state, {
        aggregationLevel,
        parentId,
        aggregateScenarios,
        aggregateStoreGroups,
        pricingGroupId,
        scenarioKey,
        historicalPeriod: state.selectedHistoricalPeriod,
        allTimeFlexiblePeriodsLoadingRequired,
      });

      const basicMutationParams = {
        level: aggregationLevel,
        aggregateStoreGroups,
      };
      const previousRequestCancellerKey = getHierarchyLevelAggregationRequestKey(
        basicMutationParams
      );
      if (has(state.timeFlexibleAggregationRequestsCanceller, previousRequestCancellerKey)) {
        state.timeFlexibleAggregationRequestsCanceller[previousRequestCancellerKey].cancel();
      }
      const axiosSource = axios.CancelToken.source();

      const params = {
        workpackageId: rootState.workpackages.selectedWorkpackage._id,
        competitorNames: getters.selectedCompetitorNames,
        attributeFilters: formattedAttributeFilters,
        alertFilter: state.alertFilter,
        ...extraParams,
      };

      commit('setTimeFlexibleAggregationsLoading', {
        ...basicMutationParams,
        isLoading: true,
      });
      commit('setTimeFlexibleAggregationRequestsCanceller', {
        ...basicMutationParams,
        canceller: axiosSource,
      });

      const [err, response] = await to(
        axios.get('/api/workpackage-product/aggregated', { params, cancelToken: axiosSource.token })
      );

      commit('setLoading', false);
      if (!aggregateScenarios && !aggregateStoreGroups) {
        commit('setAggregationLoadings', {
          level: aggregationLevel,
          aggregateStoreGroups,
          isLoading: false,
        });
      }

      if (err) {
        commit('removeTimeFlexibleAggregationRequestsCanceller', {
          ...basicMutationParams,
        });
        if (axios.isCancel(err)) {
          return;
        }
        throw new Error(err.message);
      }

      const { data: items } = response;

      commit('setTimeFlexibleAggregationsLoading', {
        ...basicMutationParams,
        isLoading: false,
      });
      commit('removeTimeFlexibleAggregationRequestsCanceller', {
        ...basicMutationParams,
      });

      if (!aggregateScenarios && !aggregateStoreGroups) {
        commit('setHierarchyLevelItems', {
          items,
          aggregationLevel,
          parentId: params.parentId,
          selectedCompetitors: getters.selectedCompetitorNames,
        });
        if (getGroupedAggregationsFlag && getSingleAggregationsFlag) {
          dispatch('compareAggregations', {
            level: aggregationLevel,
            parentId: params.parentId,
          });
        }
      }
      return { items, preventUpdate: false };
    },

    async fetchGroupedAggregatedHierarchyLevelItems(
      { commit, state, getters, rootState, rootGetters, dispatch },
      { aggregationLevel = 0, parentId, refreshCache = false, loadingMsg } = {}
    ) {
      commit('clearCachedResult');

      // both yearly and time flexible fields will be fetched at the same time
      commit('setLoadingWithMsg', loadingMsg);
      const basicMutationParams = {
        level: aggregationLevel,
        aggregateStoreGroups: false,
      };
      const previousRequestCancellerKey = getHierarchyLevelAggregationRequestKey(
        basicMutationParams
      );

      if (has(state.timeFlexibleAggregationRequestsCanceller, previousRequestCancellerKey)) {
        state.timeFlexibleAggregationRequestsCanceller[previousRequestCancellerKey].cancel();
      }

      let formattedAttributeFilters = {};

      const { retailAttributesFilter } = rootState.filters;
      if (retailAttributesFilter.length) {
        formattedAttributeFilters = formatFilters({
          where: retailAttributesFilter,
          rootState,
        });
      }

      if (isEmpty(state.selectedCompetitors)) {
        await dispatch('competitorMetadata/fetchCompetitorMetadata', null, { root: true });
      }
      const extraParams = getExtraFetchHierarchyItemAggregationParams(state, {
        aggregationLevel,
        parentId,
        aggregateScenarios: false,
        aggregateStoreGroups: false,
        historicalPeriod: state.selectedHistoricalPeriod,
      });
      const params = {
        workpackageId: rootState.workpackages.selectedWorkpackage._id,
        competitorNames: getters.selectedCompetitorNames,
        attributeFilters: formattedAttributeFilters,
        alertFilter: state.alertFilter,
        ...extraParams,
      };
      const headers = refreshCache ? { 'refresh-cache': true } : {};
      const axiosSource = axios.CancelToken.source();
      commit('setTimeFlexibleAggregationsLoading', {
        ...basicMutationParams,
        isLoading: true,
      });
      commit('setTimeFlexibleAggregationRequestsCanceller', {
        ...basicMutationParams,
        canceller: axiosSource,
      });

      const databricksEnabled = rootGetters['clientConfig/isFeatureFlagEnabled'](
        saveResultsToDatabricks
      );
      if (databricksEnabled) {
        // send databricks aggregations request to compare
        axios.get('/api/workpackage-product/aggregated/groupedDatabricks', {
          params,
          cancelToken: axiosSource.token,
          headers,
        });
      }

      const [err, response] = await to(
        axios.get('/api/workpackage-product/aggregated/grouped', {
          params,
          cancelToken: axiosSource.token,
          headers,
        })
      );

      if (err) {
        commit('setLoading', false);
        commit('setTimeFlexibleAggregationsLoading', {
          ...basicMutationParams,
          isLoading: false,
        });
        if (axios.isCancel(err)) {
          return;
        }
        // do not remove the canceller if the current request is cancelled
        // as it has been replaced and removing it will prevent future requests
        // from being cancelled leading to data mismatch for the filters
        commit('removeTimeFlexibleAggregationRequestsCanceller', {
          ...basicMutationParams,
        });
        throw new Error(err.message);
      }

      commit('setLoading', false);
      commit('setTimeFlexibleAggregationsLoading', {
        ...basicMutationParams,
        isLoading: false,
      });
      commit('removeTimeFlexibleAggregationRequestsCanceller', {
        ...basicMutationParams,
      });

      const { data: items } = response;
      const {
        'cached-result': isCachedResult,
        'cached-result-timestamp': cacheTimestamp,
      } = response.headers;
      if (isCachedResult) {
        commit('setCachedResultTimestamp', cacheTimestamp);
      }

      commit('setGroupedHierarchyLevelItems', {
        items,
        selectedCompetitors: getters.selectedCompetitorNames,
      });

      return { items };
    },

    async downloadItems(
      context,
      { isUnitManagerView, translationMap = {}, columnFormatters = {} }
    ) {
      const { commit, rootState, state } = context;
      commit('setDownloadingItems', true);
      // note this is not a job, just matching required fields for generic notification
      const jobId = 'download_grid_view';
      const { finished, failed } = jobStatuses;

      let levelEntryDescription = '';

      try {
        const scenarioKey = rootState.filters.scenario;
        const workpackageId = rootState.workpackages.selectedWorkpackage._id;
        const levelEntryKey = isUnitManagerView
          ? rootState.filters.selectedLevelEntryKey
          : rootState.filters.pricingGroup;

        const { retailAttributesFilter } = rootState.filters;
        let formattedAttributeFilters = {};
        if (retailAttributesFilter.length) {
          formattedAttributeFilters = formatFilters({
            where: retailAttributesFilter,
            rootState,
          });
        }

        const params = {
          export: true,
          formatForExport: true,
          attributeFilters: formattedAttributeFilters,
          translationMap,
          activeTableFilters: state.activeTableFilters,
          alertFilter: state.alertFilter,
          selectedHistoricalPeriod: state.selectedHistoricalPeriod,
        };

        const hierarchyEntry = await axios.get(`/api/hierarchy/levelEntryKey/${levelEntryKey}`);
        levelEntryDescription = hierarchyEntry.data.levelEntryDescription;

        const baseUrl = `/api/workpackage-product/workpackage/${workpackageId}/gridview/${levelEntryKey}`;
        const url = isUnitManagerView ? `${baseUrl}/unit` : `${baseUrl}/scenario/${scenarioKey}`;
        const response = await axios.get(url, { params });

        const { numberFormats, i18nconfig, exportConfigs } = rootState.clientConfig;
        const { overrideNumberFormat } = i18nconfig;
        const { rowsPerFile } = exportConfigs.exportToExcel;
        downloadXlsxFile(response.data, 'workpackage_product_data.xlsx', {
          rowsPerFile,
          columnFormatters,
          numberFormatsConfig: numberFormats[overrideNumberFormat],
        });
        triggerStatusNotification({
          context,
          jobId,
          jobStatus: finished,
          notificationPayload: levelEntryDescription,
        });
      } catch (e) {
        console.error(e);
        triggerStatusNotification({
          context,
          jobId,
          jobStatus: failed,
          notificationPayload: levelEntryDescription,
        });
      } finally {
        commit('setDownloadingItems', false);
      }
    },
    // Try to poll argo job status every second until it's done.
    // If for 20 seconds the job is still running(unlikely), stop polling and update the scenario results.
    async waitForArgoRunPricingJobCompletion(context, { payload: scenarioKey, jobStatus }) {
      const { dispatch, state, rootState } = context;
      const { scenarioMetadata: scenarioMetadataArray } = rootState.scenarioMetadata;
      const scenarioMetadata = find(scenarioMetadataArray, { scenarioKey });
      const notificationPayload = get(scenarioMetadata, 'scenarioDescription');
      let attempts = 0;
      const maxAttempts = 20;
      const intervalId = setInterval(() => {
        (async () => {
          // check on condition to exit intervall: argo job is complete or max attempts reached
          if (!state.calculatingScenarioResults || attempts >= maxAttempts) {
            clearInterval(intervalId);
            await dispatch('updateScenarioResults', { jobStatus });
            const jobId = 'run_pricing';

            triggerStatusNotification({
              context,
              jobId,
              jobStatus,
              notificationPayload,
              argoWorkflow: true,
            });
          } else {
            dispatch('checkPricingEngineStatus');
            attempts += 1;
          }
        })();
      }, 1000);
    },

    async waitForArgoRunPricingForAllJobCompletion(context, { payload: workpackageId, jobStatus }) {
      const { dispatch, state } = context;

      let attempts = 0;
      const maxAttempts = 20;
      const intervalId = setInterval(() => {
        (async () => {
          if (!state.recalculatingAll || attempts >= maxAttempts) {
            clearInterval(intervalId);
            await dispatch('postRunEngineForAllScenarios', { payload: workpackageId });
            const jobId = 'run_pricing_for_all';

            const notificationPayload = getWorkpackageDescription({ context, workpackageId });
            triggerStatusNotification({
              context,
              jobId,
              jobStatus,
              notificationPayload,
              argoWorkflow: true,
            });
          } else {
            dispatch('checkRecalculatingAll', workpackageId);
            attempts += 1;
          }
        })();
      }, 1000);
    },

    async waitForArgoRunPricingForPgsJobCompletion(context, { payload, jobStatus }) {
      const { dispatch, state } = context;
      const [workpackageId, levelEntryKey] = payload.split('?');

      let attempts = 0;
      const maxAttempts = 20;
      const intervalId = setInterval(() => {
        (async () => {
          const calculatingPgResults = get(state.calculatingPgResults, payload, false);
          if (!calculatingPgResults || attempts >= maxAttempts) {
            clearInterval(intervalId);
            await dispatch('postRunEngineForPricingGroups', { payload });
            const jobId = 'run_pricing_for_pgs';
            const notificationPayload = getWorkpackageDescription({ context, workpackageId });

            triggerStatusNotification({
              context,
              jobId,
              jobStatus,
              notificationPayload,
              argoWorkflow: true,
            });
          } else {
            dispatch('checkRecalculatingPgs', { workpackageId, levelEntryKey });
            attempts += 1;
          }
        })();
      }, 1000);
    },

    async waitForArgoRunSpecificPricingGroupsJobCompletion(context, { jobStatus }) {
      const { dispatch, rootState, getters } = context;
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      let attempts = 0;
      const maxAttempts = 20;
      // we need to fetch job status initially because failed/finished event could come earlier than gridView makes initial getRecalculatingSpecificPricingGroups
      await dispatch('getRecalculatingSpecificPricingGroups');
      const intervalId = setInterval(() => {
        (async () => {
          if (!getters.hasUnfinishedSpecificPgJobs || attempts >= maxAttempts) {
            clearInterval(intervalId);
            const jobId = 'run_pricing_for_specific_pgs';
            const notificationPayload = getWorkpackageDescription({ context, workpackageId });

            triggerStatusNotification({
              context,
              jobId,
              jobStatus,
              notificationPayload,
              argoWorkflow: true,
            });
            // refetch hierarchies to add new PGs to GridView
            dispatch('fetchGroupedAggregatedHierarchyLevelItems', { refreshCache: true });
          } else {
            await dispatch('getRecalculatingSpecificPricingGroups');
            attempts += 1;
          }
        })();
      }, 1000);
    },

    async checkPricingEngineStatus({ commit, rootState }, scenarioKey = null) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      scenarioKey = scenarioKey || rootState.filters.scenario;
      if (workpackageId && scenarioKey) {
        const [err, res] = await to(
          axios.get(
            `/api/pricing-engine/workpackage/${workpackageId}/scenario/${scenarioKey}/status`
          )
        );
        if (err) {
          commit('setCalculatingScenarioResults', false);
          throw new Error(err.message);
        }
        const { data: engineIsRunning } = res;
        commit('setCalculatingScenarioResults', engineIsRunning);
      }
    },

    async runPricingEngine({ commit }, { runParameters: kwargs }) {
      commit('setCalculatingScenarioResults', true);

      try {
        await axios.post('/api/pricing-engine/dag', {
          params: kwargs,
        });
      } catch (err) {
        commit('setCalculatingScenarioResults', false);
        console.info(err);
      }
    },

    async runEngineForSpecificPricingGroups({ rootState }, { pgKeys }) {
      if (isEmpty(pgKeys)) return;
      const selectedWorkpackageId = get(rootState.workpackages.selectedWorkpackage, '_id', null);

      const [err] = await to(
        axios.post('/api/pricing-engine/specific-pricing-groups', {
          params: {
            workpackage_id: selectedWorkpackageId,
            level_entry_keys: pgKeys,
          },
        })
      );

      if (err) console.error(err);

      return err === null;
    },

    async runEngineForPricingGroups({ rootState, commit }, { level = 1, levelEntryKey = null }) {
      if (!levelEntryKey) return;
      const selectedWorkpackageId = get(rootState.workpackages.selectedWorkpackage, '_id', null);
      commit('setCalculatingPgResults', {
        workpackageId: selectedWorkpackageId,
        levelEntryKey,
        isCalculating: true,
      });

      const [err] = await to(
        axios.post('/api/pricing-engine/pricing-groups', {
          params: {
            workpackage_id: selectedWorkpackageId,
            level,
            level_entry_key: levelEntryKey,
          },
        })
      );

      if (err) {
        commit('setCalculatingPgResults', {
          workpackageId: selectedWorkpackageId,
          levelEntryKey,
          isCalculating: false,
        });
      }
      return err === null;
    },

    async postRunEngineForPricingGroups({ rootState, rootGetters, commit, dispatch }, { payload }) {
      const selectedWorkpackageId = get(rootState.workpackages.selectedWorkpackage, '_id', null);
      const [workpackageId, levelEntryKey] = payload.split('?');
      commit('setCalculatingPgResults', {
        workpackageId,
        levelEntryKey,
        isCalculating: false,
      });
      if (workpackageId !== selectedWorkpackageId) {
        return;
      }
      const unitIds = rootGetters['hierarchy/getApprovedUnitsForWorkpackage'];
      dispatch('getFailedRuns');
      await Promise.all([
        dispatch('fetchAggregations'),
        dispatch('scenarioMetadata/getAggregatedScenarios', {}, { root: true }),
        dispatch('getAlertCounts'),
        ...unitIds.map(unitId =>
          dispatch(
            'hierarchy/unsetUMApprovalIfPriceChanged',
            { levelEntryKey: unitId },
            { root: true }
          )
        ),
      ]);
    },

    async runEngineForAllScenarios({ rootState, state, commit }) {
      if (state.calculatingScenarioResults) return;
      const selectedWorkpackageId = get(rootState.workpackages.selectedWorkpackage, '_id', null);
      commit('setRecalculatingAll', true);
      commit('setLoadingRecalculateAllResults', true);
      const [err] = await to(
        axios.post('/api/pricing-engine/all', {
          params: {
            workpackage_id: selectedWorkpackageId,
          },
        })
      );

      if (err) {
        commit('setRecalculatingAll', false);
        commit('setLoadingRecalculateAllResults', false);
      }
      return err === null;
    },

    async postRunEngineForAllScenarios(
      { rootState, rootGetters, commit, dispatch },
      { payload: workpackageId }
    ) {
      // possible issue with design here, we don't keep track of jobs per workpackage.
      // we rely on reloading components to check for running jobs,
      // but if we trigger engine on workpackage A and change to workpackage B and trigger it as well,
      // we don't want the spinner state on the current page (workpackage B) to update based on the first job, only the second job.
      // hence the early exit here if workapckageId doesn't match selectedWorkpackage.
      // ideally we'd track jobs based on ids, and always update state,
      // but this would make the vuex state much more complex.

      // if selectedWorkpackageId is not defined, default to workpackageId in response and set loading to false
      const selectedWorkpackageId =
        get(rootState.workpackages.selectedWorkpackage, '_id', null) || workpackageId;
      if (workpackageId !== selectedWorkpackageId) {
        return;
      }
      commit('setRecalculatingAll', false);
      const unitIds = rootGetters['hierarchy/getApprovedUnitsForWorkpackage'];
      dispatch('getFailedRuns');
      await Promise.all([
        dispatch('fetchAggregations'),
        dispatch('scenarioMetadata/getAggregatedScenarios', {}, { root: true }),
        dispatch('getAlertCounts'),
        ...unitIds.map(unitId =>
          dispatch(
            'hierarchy/unsetUMApprovalIfPriceChanged',
            { levelEntryKey: unitId },
            { root: true }
          )
        ),
      ]);
      commit('setLoadingRecalculateAllResults', false);
    },

    async getFailedRuns({ rootState, commit }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, { data }] = await to(
        axios.get(`/api/scenario-metadata/workpackage/${workpackageId}/failures`)
      );
      if (err) console.error(err);

      commit('setFailedRuns', data);
      return data;
    },

    async handleRecalculate({ state, dispatch }, { scenarioKey, workpackageId }) {
      const params = {
        scenario_key: scenarioKey,
        workpackage_id: workpackageId,
      };
      const unitId = state.expandedHierarchyLevelItems[unitLevel + 1];
      await dispatch('runPricingEngine', { runParameters: params });
      await Promise.all([dispatch('fetchAggregations'), dispatch('getAlertCounts')]);
      await dispatch('scenarioMetadata/getAggregatedScenarios', {}, { root: true });
      dispatch(
        'hierarchy/unsetUMApprovalIfPriceChanged',
        { levelEntryKey: unitId },
        { root: true }
      );
    },

    clearItems({ commit }) {
      commit('setItems', { items: [] });
    },

    setExpandedHierarchyLevelItems({ commit }, { newExpandedHierarchy }) {
      if (!newExpandedHierarchy) commit('initExpandedHierarchyLevelItems');
      else commit('setExpandedHierarchyLevelItems', newExpandedHierarchy);
    },

    resetExpandedHierarchyLevels({ commit }) {
      commit('resetExpandedHierarchyLevels');
    },

    async updateScenarioResults({ rootState, commit, dispatch, state }, { jobStatus }) {
      commit('setCalculatingScenarioResults', false);
      await dispatch('getFailedRuns');
      if (jobStatus === jobStatuses.finished) {
        await dispatch('fetchItems', { levelEntryKey: rootState.filters.pricingGroup });
        if (!isEmpty(state.expandedProduct)) {
          const productSpecificPromises = [
            dispatch('fetchFullProductInfo', {
              scenarioKey: state.expandedProduct.scenarioResults.scenarioKey,
              productKey: state.expandedProduct.article.productKey,
              toolStoreGroupKey: state.expandedProduct.toolStoreGroupKey,
            }),
          ];
          if (rootState.clientConfig.toggleLogic[displayRulesListView]) {
            productSpecificPromises.push(
              dispatch('fetchProductSettings', {
                scenarioKey: state.expandedProduct.scenarioResults.scenarioKey,
                productKey: state.expandedProduct.article.productKey,
                toolStoreGroupKey: state.expandedProduct.toolStoreGroupKey,
              })
            );
          }
          await Promise.all(productSpecificPromises);
        }
      }
    },

    async canEngineRun({ rootState }, { pricingGroupId }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, response] = await to(
        axios.get(
          `/api/workpackage-product/workpackage/${workpackageId}/pricingGroup/${pricingGroupId}/canEngineRun`
        )
      );

      if (err) {
        throw new Error(err.message);
      }
      return response.data;
    },

    async updateOverridePrice(
      { rootState, state, dispatch },
      { item, intentionPrice, alertParameters: kwargs, usePromoPriceOnSmartOverride = false }
    ) {
      const enabledCompetitorPrices = Object.values(
        merge(item.competitorPrices.feed, item.competitorPrices.manual)
      )
        .filter(c => !c.disabled)
        .reduce((output, { competitorDescription, competitorPrice }) => {
          output[competitorDescription] = competitorPrice;
          return output;
        }, {});

      const goLiveDate = rootState.workpackages.selectedWorkpackage.goLiveDate;
      const scenarioPriceForCalculation = computeScenarioPrice({
        item,
        goLiveDate,
        usePromoPriceOnSmartOverride,
        intentionPrice,
      });
      const analyticsIncludingVat =
        rootState.workpackages.selectedWorkpackage.analyticsIncludingVat;

      const kpiInput = {
        starting_price: intentionPrice,
        live_price: item.livePrice,
        live_cost: item.liveCost,
        scenario_price: scenarioPriceForCalculation,
        yearly_volume: item.yearlyVolume,
        ...volumeKpiInputFields(item),
        non_promo_net_cost: item.mandatoryEngineInputs.nonPromoNetCost,
        promo_participation_sales: item.mandatoryEngineInputs.promoParticipationSales / 100,
        promo_discount: item.mandatoryEngineInputs.promoDiscount / 100,
        promo_funding: item.mandatoryEngineInputs.promoFunding / 100,
        vat_rate: item.vatRate,
        vat_calc: item.vatCalc || analyticsIncludingVat ? item.vatRate : 0,
        competitors: enabledCompetitorPrices,
        content_value: item.contentValue ? item.contentValue : 0,
        norm_weight: isNumber(item.normWeight) ? item.normWeight : null,
      };

      const scenarioResultsForItem = {
        productKey: item.productKey,
        productKeyDisplay: item.productKeyDisplay,
        toolStoreGroupKey: item.toolStoreGroupKey,
        economicReferencePrice: item.economicReferencePrice,
        architectureReferencePrice: item.architectureReferencePrice,
        competitorReferencePrice: item.competitorReferencePrice,
        scenarioPrice: {
          price: item.scenarioPrice,
          pricePerNormWeight: item.scenarioPricePerNormWeight,
          pricePerContentUnit: item.scenarioPricePerContentUnit,
        },
        optimizedPrice: item.optimizedPrice,
        scenarioPriceChange: item.scenarioPriceChange,
        scenarioPriceChangeRatio: item.scenarioPriceChangeRatio,
        scenarioSalesImpact: item.scenarioSalesImpact,
        regularSalesImpact: item.regularSalesImpact,
        scenarioCostImpact: item.scenarioCostImpact,
        regularCostImpact: item.regularCostImpact,
        scenarioMargin: item.scenarioMargin,
        overridePrice: item.overridePrice,
        competitors: item.competitors || [],
        scenarioKey: item.scenarioKey,
        workpackageId: item.workpackageId,
        competitorPenalty: item.competitorPenalty,
        economicPenalty: item.economicPenalty,
        architecturePenalty: item.architecturePenalty,
        priceRange: item.priceRange,
        alerts: item.alerts,
      };
      // call recalculate...
      const [err, response] = await to(
        axios.post(`/api/scenario-results/${kwargs.scenario_key}/recalculate-kpis`, {
          kpiInput,
          scenarioResults: scenarioResultsForItem,
          kwargs,
          analyticsIncludingVat,
        })
      );
      if (err) throw new Error(err.response.data);

      const scenarioResult = response.data.scenarioResults;

      // update the item
      forEach(Object.keys(scenarioResult), key => {
        if (has(item, key)) {
          if (key === 'scenarioPrice') {
            item[key] = scenarioResult[key].price;
            // show regular price if normWeight price is null
            item.scenarioPricePerNormWeight =
              scenarioResult[key].pricePerNormWeight || scenarioResult[key].price;
            item.scenarioPricePerContentUnit = scenarioResult[key].pricePerContentUnit;
          } else if (includes(key, 'PerNormWeight')) {
            item[key] = scenarioResult[key] || scenarioResult[replace(key, 'PerNormWeight', '')];
          } else item[key] = scenarioResult[key];
        }
      });

      // item.competitor1/2 are used to display the data in the row
      item.competitor1.competitorIndex = get(
        find(scenarioResult.competitors, {
          competitorName: item.competitor1.competitorName,
        }),
        'competitorIndex'
      );
      item.competitor2.competitorIndex = get(
        find(scenarioResult.competitors, {
          competitorName: item.competitor2.competitorName,
        }),
        'competitorIndex'
      );
      // Update the alerts for the product after an override
      item.alerts = response.data.alerts[item.productKey];

      dispatch('getAlertCounts');
      dispatch(
        'hierarchy/unsetUMApprovalIfPriceChanged',
        { levelEntryKey: item.hierarchy[unitLevel].id },
        { root: true }
      );
      // If a product is expanded and we override the price, fetch updates for the expanded view
      if (
        !isEmpty(state.expandedProduct) &&
        state.expandedProduct.scenarioResults.scenarioKey === item.scenarioKey &&
        state.expandedProduct.article.productKey === item.productKey
      ) {
        await dispatch('fetchFullProductInfo', {
          scenarioKey: state.expandedProduct.scenarioResults.scenarioKey,
          productKey: state.expandedProduct.article.productKey,
          toolStoreGroupKey: state.expandedProduct.toolStoreGroupKey,
        });
      }
    },

    async fetchAggregations({ state, dispatch, commit, rootState }) {
      commit('setLoading', true);
      const aggregations = [];
      const getGroupedAggregationsFlag = rootState.clientConfig.toggleLogic[getGroupedAggregations];
      const getSingleAggregationsFlag = rootState.clientConfig.toggleLogic[getSingleAggregations];
      // cancel aggregation requests
      await dispatch('cancelTimeFlexibleAggregationRequests');
      commit('resetAggregationLoadings');

      // fetch the aggregated results:
      if (getGroupedAggregationsFlag)
        aggregations.push(
          dispatch('fetchGroupedAggregatedHierarchyLevelItems', { refreshCache: true })
        );

      if (getSingleAggregationsFlag) {
        forEach(range(categoryLevel, architectureGroupLevel), aggregationLevel => {
          const parentId = state.expandedHierarchyLevelItems[aggregationLevel];
          if (parentId) {
            aggregations.push(
              dispatch('fetchAggregatedHierarchyLevelItems', { aggregationLevel, parentId })
            );
          }
        });

        // top-level aggregations:
        aggregations.push(
          dispatch('fetchAggregatedHierarchyLevelItems'),
          dispatch('fetchAggregatedHierarchyLevelItems', { aggregationLevel: unitLevel })
        );
      }

      // if store group aggregations are displayed, update them
      if (!isEmpty(state.expandedStoreGroupAggregations)) {
        aggregations.push(dispatch('fetchAllStoreGroupAggregations'));
      }

      await Promise.all(aggregations);
      commit('setLoading', false);
    },

    async toggleStoreGroupAggregations(
      { state, commit, dispatch },
      { level, parentId, scenarioKey = null }
    ) {
      const storeGroupAggregation = {
        level,
        parentId,
        scenarioKey,
      };
      const isExpanded = state.expandedStoreGroupAggregations.some(
        areStoreGroupAggregationsEqual(storeGroupAggregation)
      );
      if (isExpanded) {
        commit('removeExpandedStoreGroupAggregation', storeGroupAggregation);
        return;
      }
      commit('addExpandedStoreGroupAggregation', storeGroupAggregation);
      dispatch('fetchStoreGroupAggregations', storeGroupAggregation);
    },

    async fetchStoreGroupAggregations(
      { commit, dispatch, rootState },
      { level, parentId, scenarioKey }
    ) {
      commit('setLoadingStoreGroupAggregations', true);
      const params = getFetchStoreGroupAggregationsParams({ level, parentId, scenarioKey });
      const storeGroupOrder = rootState.clientConfig.storeGroupOrderConfig;
      const [err, results] = await to(dispatch('fetchAggregatedHierarchyLevelItems', params));
      if (err) {
        commit('setLoadingStoreGroupAggregations', false);
        commit('updateExpandedStoreGroupAggregation', {
          level,
          parentId,
          scenarioKey,
          loading: false,
          storeGroupOrder,
        });
        return Promise.reject(err);
      }
      if (!results.preventUpdate) {
        commit('updateExpandedStoreGroupAggregation', {
          level,
          parentId,
          scenarioKey,
          loading: false,
          data: results.items,
          storeGroupOrder,
        });
      }
      commit('setLoadingStoreGroupAggregations', false);
    },

    async fetchAllStoreGroupAggregations({ state, commit, dispatch, rootState }) {
      commit('setLoadingStoreGroupAggregations', true);
      const aggregations = state.expandedStoreGroupAggregations.map(agg => ({
        ...agg,
        loading: true,
        data: [],
      }));
      commit('setExpandedStoreGroupAggregations', aggregations);
      const requests = aggregations.map(agg => {
        const params = getFetchStoreGroupAggregationsParams(agg);
        return dispatch('fetchAggregatedHierarchyLevelItems', params);
      });
      const [err, results] = await to(Promise.all(requests));
      if (err) {
        commit('setLoadingStoreGroupAggregations', false);
        const newAggregations = aggregations.map(agg => ({
          ...agg,
          loading: false,
        }));
        commit('setExpandedStoreGroupAggregations', newAggregations);
        return Promise.reject(err);
      }
      const storeGroupOrder = rootState.clientConfig.storeGroupOrderConfig;
      const newAggregations = aggregations.map((agg, index) => {
        const data = results[index].preventUpdate
          ? agg.data
          : mapStoreGroupAggregationsData({
              data: results[index].items,
              products: state.items,
              storeGroupOrder,
            });
        return {
          ...agg,
          loading: false,
          data,
        };
      }, []);
      commit('setExpandedStoreGroupAggregations', newAggregations);
      commit('setLoadingStoreGroupAggregations', false);
    },

    async fetchScenariosStoreGroupAggregations({ state, dispatch }) {
      if (isEmpty(state.expandedStoreGroupAggregations)) return;
      const areScenariosStoreGroupAggregationsExpanded = state.expandedStoreGroupAggregations.some(
        agg => !!agg.scenarioKey
      );
      if (!areScenariosStoreGroupAggregationsExpanded) return;
      return dispatch('fetchAllStoreGroupAggregations');
    },

    setSelectedCompetitors({ commit }, selectedCompetitors) {
      commit('setSelectedCompetitors', selectedCompetitors);
    },

    setSelectedCompetitorAtIndex({ commit }, { selectedCompetitor, index }) {
      commit('setSelectedCompetitorAtIndex', { selectedCompetitor, index });
    },

    updatePricingGroupsToRelease({ commit }, { pg }) {
      commit('updatePricingGroupsToRelease', { pg });
    },

    resetPricingGroupsToRelease({ commit }) {
      commit('resetPricingGroupsToRelease');
    },

    setCategoryToRelease({ commit }, { category }) {
      commit('setCategoryToRelease', { category });
    },

    setPricingGroupsToRelease({ commit }, { pricingGroups }) {
      commit('setPricingGroupsToRelease', { pricingGroups });
    },

    setSelectedWeighting({ commit }, { selectedWeighting }) {
      commit('setSelectedWeighting', { selectedWeighting });
    },

    setShowRegularImpact({ commit }, { showRegularImpact }) {
      commit('setShowRegularImpact', { showRegularImpact });
    },

    setSelectedHistoricalPeriod({ commit }, { selectedHistoricalPeriod }) {
      commit('setSelectedHistoricalPeriod', { selectedHistoricalPeriod });
    },

    resetState({ commit, dispatch }) {
      dispatch('cancelTimeFlexibleAggregationRequests');
      commit('resetState');
    },

    setExpandedProduct({ commit }, product) {
      commit('setExpandedProduct', product);
    },

    async setAlertFilter({ commit, dispatch }, alertType) {
      commit('setAlertFilter', alertType);
      await Promise.all([
        dispatch('fetchAggregations'),
        dispatch('scenarioMetadata/getAggregatedScenarios', {}, { root: true }),
      ]);
    },

    async upsertActiveTableFilter({ commit, dispatch }, filter) {
      if (isArray(filter)) filter.forEach(f => commit('upsertActiveTableFilter', f));
      else commit('upsertActiveTableFilter', filter);
      await Promise.all([
        dispatch('scenarioMetadata/getAggregatedScenarios', {}, { root: true }),
        dispatch('fetchScenariosStoreGroupAggregations'),
      ]);
    },

    async removeActiveTableFilter({ commit, dispatch }, { filter, skipFetch = false }) {
      if (isArray(filter)) filter.forEach(f => commit('removeActiveTableFilter', f));
      else commit('removeActiveTableFilter', filter);
      await Promise.all([
        ...(skipFetch
          ? []
          : [dispatch('scenarioMetadata/getAggregatedScenarios', {}, { root: true })]),
        dispatch('fetchScenariosStoreGroupAggregations'),
      ]);
    },

    async getAlertCounts({ state, rootState, commit }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const { retailAttributesFilter } = rootState.filters;
      let formattedAttributeFilters = {};

      if (retailAttributesFilter.length) {
        formattedAttributeFilters = formatFilters({
          where: retailAttributesFilter,
          rootState,
        });
      }

      const params = {
        workpackageId,
        attributeFilters: formattedAttributeFilters,
      };

      const [err, response] = await to(
        axios.get('/api/workpackage-product/alertCounts', { params })
      );

      if (err) {
        throw new Error(err.message);
      }
      commit('setAlertCounts', response.data);

      // In the event that we are filtering using the attribute filter also
      // If the alert counts is zero for the current alert filter
      // We set it to null to prevent no hierarchy levels appearing on GV
      if (get(state.alertCounts, state.alertFilter, 0) === 0) {
        commit('setAlertFilter', null);
      }
    },

    async toggleFilteringByArchitectureGroup(
      { commit, dispatch },
      {
        isFilterEnabled,
        architectureGroupAttribute,
        architectureGroupId,
        attributeFiltersToKeep = [],
      }
    ) {
      if (isFilterEnabled) {
        dispatch('filters/restoreRetailAttributesFilter', null, { root: true });
        commit('restoreGridViewFilters');
        return;
      }

      const architectureGroupFilter = {
        ...architectureGroupAttribute,
        attributeValue: [architectureGroupId],
        predicate: filterPredicates.IN,
        disabled: true,
      };
      dispatch(
        'filters/saveAndResetRetailAttributesFilter',
        [...attributeFiltersToKeep, architectureGroupFilter],
        { root: true }
      );
      commit('saveAndResetGridViewFilters');
    },

    cancelTimeFlexibleAggregationRequests({ commit, state }) {
      Object.values(state.timeFlexibleAggregationRequestsCanceller).forEach(canceller => {
        canceller.cancel();
      });
      commit('resetTimeFlexibleAggregationRequestsCanceller');
      commit('resetTimeFlexibleAggregationsLoading');
    },
  },
};

export default store;
