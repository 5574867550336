import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input-screen-page-wrapper",
      staticStyle: { width: "100%", height: "100%" },
    },
    [
      _c("inputs-grid", {
        ref: "inputsGrid",
        attrs: {
          "additional-columns": _vm.additionalColumns,
          "additional-product-columns": _vm.additionalProductColumns,
          "additional-grid-options": _vm.additionalGridOptions,
          "save-changes": _vm.saveChanges,
          delete: _vm.openDeleteDialog,
          "export-action": _vm.downloadItems,
          "is-exporting": _vm.downloadingItems,
        },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "feature-toggle",
                  { attrs: { toggle: _vm.displayAddCompetitorButton } },
                  [
                    _c(
                      VBtn,
                      {
                        attrs: { color: "primary", small: "", depressed: "" },
                        on: {
                          click: function ($event) {
                            return _vm.openCompetitorCreateUpdateDialog(
                              _vm.dialogModes.create
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("actions.add")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "feature-toggle",
                  { attrs: { toggle: _vm.displayCompetitorUpload } },
                  [
                    _c("competitor-upload", {
                      attrs: {
                        "enabled-value-translations":
                          _vm.enabledValueTranslations,
                        "is-master-workpackage":
                          _vm.isSelectedWorkpackageMaster,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("competitor-create-update-dialog", {
        key: _vm.competitorCreateDialogKey,
        attrs: {
          "is-open": _vm.isCompetitorCreateUpdateDialogOpen,
          mode: _vm.mode,
          competitor: _vm.competitorToEdit,
        },
        on: { closeDialog: _vm.closeCompetitorCreateUpdateDialog },
      }),
      _vm._v(" "),
      _c("alert-dialog", {
        attrs: {
          "is-open": _vm.isDeleteDialogOpen,
          "ok-btn-text": _vm.$t("actions.delete"),
          "disable-ok-button":
            _vm.checkingIfCompetitorCanBeDeleted || !_vm.competitorCanBeDeleted,
        },
        on: {
          onOk: function ($event) {
            return _vm.deleteCompetitorHandler(true)
          },
          onCancel: function ($event) {
            return _vm.deleteCompetitorHandler(false)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(_vm._s(_vm.$t("dialogs.deleteCompetitorDialog.header"))),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                !_vm.checkingIfCompetitorCanBeDeleted &&
                _vm.competitorCanBeDeleted
                  ? _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("dialogs.deleteCompetitorDialog.body")
                          ) +
                          "\n        "
                      ),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.competitorToDelete
                              ? _vm.competitorToDelete
                                  .competitorDisplayDescription
                              : ""
                          )
                        ),
                      ]),
                    ])
                  : _vm.checkingIfCompetitorCanBeDeleted &&
                    _vm.competitorUses.length === 0
                  ? _c(
                      "div",
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t(
                                "competitorMatches.deleteMessages.checkingIfTheCompetitor"
                              )
                            ) +
                            "\n        "
                        ),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.competitorToDelete
                                ? _vm.competitorToDelete
                                    .competitorDisplayDescription
                                : ""
                            )
                          ),
                        ]),
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t(
                                "competitorMatches.deleteMessages.canBeDeleted"
                              )
                            ) +
                            "\n        "
                        ),
                        _c(VProgressCircular, {
                          attrs: {
                            indeterminate: "",
                            color: "primary",
                            size: "20",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c("div", { staticClass: "pb-1" }, [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.competitorToDelete
                                  ? _vm.competitorToDelete
                                      .competitorDisplayDescription
                                  : ""
                              )
                            ),
                          ]),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "competitorMatches.deleteMessages.competitorCannotBeDeleted"
                                )
                              ) +
                              ":\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(
                          _vm.competitorUses,
                          function (competitorUse, index) {
                            return _c("div", { key: index }, [
                              _c(
                                "div",
                                { staticClass: "pl-2" },
                                [
                                  _c(
                                    VIcon,
                                    {
                                      staticClass: "pb-1",
                                      attrs: {
                                        size: "1.7rem",
                                        color: _vm.alertsIconColor,
                                      },
                                    },
                                    [_vm._v("warning")]
                                  ),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          `competitorMatches.deleteMessages.${competitorUse.translationKey}`,
                                          competitorUse.translationParams
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ],
                                1
                              ),
                            ])
                          }
                        ),
                      ],
                      2
                    ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }